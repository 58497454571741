@use '../../styles/mixins';
@use '../../styles/variables';

.logo {
  width: 130px;
  margin-top: 16px;
  margin-bottom: 24px;
}

.formContainer {
  display: table-cell;
  vertical-align: middle;
}

.App, .Login {
  form {
    position: relative;
    width: 80%;
    max-width: 400px;
    margin: auto;
    padding: 16px 32px 32px;
    border: 1px solid variables.$gray-lightest;
    @include mixins.box-shadow(0, 0, 10px);
    @include mixins.border-radius(3px);

    .link {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  
    .floatinput {
      margin-bottom: 20px !important;
    }
  
    .button {
      max-width: 250px;
      width: 100%;
    }
  
    .createStoreMapLoader {
      margin: 54px 0 0;
      height: 50px;
      font-weight: bold;
    }
  }
}

@use 'mixins';
@use 'variables';

//
// Scaffolding
// --------------------------------------------------

// Reset the box-sizing
//
// Heads up! This reset may cause conflicts with some third-party widgets.
// For recommendations on resolving such conflicts, see
// http://getbootstrap.com/getting-started/#third-box-sizing
* {
  @include mixins.box-sizing(border-box);
}
*:before,
*:after {
  @include mixins.box-sizing(border-box);
}

// Body reset

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: variables.$background-color;
}

body {
  font-size: 12px;
  color: variables.$content-color;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

// Links

a,
.link {
  color: variables.$button-color;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: darken(variables.$button-color, 5%);
    text-decoration: underline;
  }

  &:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
}

// Figures
figure {
  margin: 0;
}

// Images
img {
  max-width: 100%;
  vertical-align: middle;
}

// Horizontal rules

hr {
  margin-top: variables.$margin;
  margin-bottom: variables.$margin;
  border: 0;
  border-top: 1px solid variables.$gray;
}

// iOS "clickable elements" fix for role="button"
//
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

[role="button"] {
  cursor: pointer;
}

.grid-layout {
  width: 100%;
  background-color: #fff;
  position: relative;
  text-align: center;
  padding: 100px 8%;
  .layout-container {
    display: inline-block;
    max-width: 1024px;
    text-align: left;
    width: 100%;
  }
}

// typical content
.standard-content {
  width: 100%;
  background-color: #fff;
  position: relative;
  text-align: center;
  padding: 100px 8%;
}
.standard-container {
  display: inline-block;
  text-align: left;
  width: 100%;
}

.removed,
.hidden,
[hidden] {
  display: none !important;
}

.fullWidth {
  width: 100% !important;
}

.invisible {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.shadow {
  @include mixins.box-shadow(0, 0, 10px, rgba(0, 0, 0, 0.05));
}

[disabled],
.disabled,
.disable {
  opacity: 0.3 !important;
  pointer-events: none;
  cursor: not-allowed;
}

.required {
  color: variables.$warning-color;
}

.hide {
  display: none !important;
}

.center,
.align-center {
  text-align: center;
  justify-content: center;
}

.left {
  text-align: left;
  justify-content: left;
}

.right {
  text-align: right;
  justify-content: right;
}

.formErrorMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 50px auto variables.$padding auto;
  max-width: 500px;
  padding: 0 variables.$padding;

  span,
  p {
    vertical-align: middle;
    color: variables.$warning-color;
  }
  span {
    font-size: 50px;
    text-shadow: 0px 5px rgba(0, 0, 0, 0.05);
    margin: 0 20px;
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation-delay: 0.5s;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  p {
    text-align: left;
    margin: 0;
  }
}

// Iphone 5
@media (max-width: variables.$screen-ip5) {
  .formErrorMessage {
    span {
      font-size: 30px;
    }
    p {
      font-size: 1.2em;
    }
  }
}

// Scale up from mobile
@media (min-width: variables.$screen-sm) {
  html {
    background: variables.$gray-white;
  }

  .box {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
    max-width: 800px;
    text-align: left;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
    padding: variables.$padding * 2.5 variables.$padding;
    margin: 50px auto 0;
  }
}

.emp {
  color: #000;
}

.element-focus-added {
  transition: none !important;
}

.bold {
  font-weight: bold;
}

/****** ACCESSIBILITY *******/
.aria-label-text {
  display: inline-block;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

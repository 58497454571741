@use 'variables';
@use 'mixins';
@use 'sass:math';

//
// Buttons
// --------------------------------------------------
button, .button {
	@include mixins.transition(all, 0.15s, linear);
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 0;
	vertical-align: top;
	cursor: pointer;
	background: variables.$button-color;
	white-space: nowrap;
	padding: math.div(variables.$padding, 2) variables.$padding;
	font-size: 1.3em;
	@include mixins.border-radius(50px);
	@include mixins.user-select(none);
	color: white;
	border: none;
	outline: none !important;
	max-height: 64px;

	&.element-focus-added {
		@include mixins.transition(none);
	}

	&:hover, &:active, &:focus {
		background: darken(variables.$button-color, 5%);
		color: white;
		text-decoration: none;
	}

	&:active, &.active {
		outline: 0;
		background-image: none;
	}

	&.inverse {
		border: 2px solid variables.$button-color; 
		background: none;
		color: variables.$button-color;
		font-weight: bold;
		padding: 18px 36px;

		&:hover, &:active{
			background: variables.$button-color;
			color: white;
		}
	}

	&.clear{
		background: none;
		color: variables.$button-color;

		&:hover, &:active, &:focus{
			background: variables.$gray-white;
			text-decoration:none;
		}
	}

	&.small{
		padding: 10px variables.$padding*2; 
		font-size: 1.3em;
	}

	&.medium{
		padding: variables.$padding variables.$padding*3; 
		font-size: 1.4em;
	}
	
	&.large{
		padding: variables.$padding variables.$padding*2;
		width: 100%;
		font-size: 1.6em;
		max-width: 400px;
		height: auto;
	}

	&.primary {
		width: 90%;
		margin: variables.$padding * 2 5%;
		padding: 20px;
		font-size: 1.6em;
	}

	&.floating-button {
		padding: variables.$padding;

		&.large{
			padding: variables.$padding variables.$padding*2;
		}
	}
	
	label{
		color: white;
		font-size: 18px;
		line-height: 18px;
		margin:0;
		padding: math.div(variables.$padding, 2);
		z-index: 1;
	}
}

@media (min-width: variables.$screen-ip5) {
    .segment {        
        button{
            &.many{
                padding: 0 26px;
            }
        }
    }
}

@media (max-width: variables.$screen-ip5) {
    button, .button {
		&.large {
			padding: 16px 20px;
		}
	}
}

.tappable {
	font-weight: bold;
	cursor: pointer;
	border-radius: 100%;

	&:hover {
        background: rgba(variables.$gray-light, 0.5);
    }
}

html, body, #root {
  margin: 0;
  width: 100%;
  height: 100%;
}


.App, .Login {
  display: table;
  width: 100%;
  height: 100%;
  text-align: center;
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

#alert-root {
  .alert-modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  .backdrop {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.95);
  }

  .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.95);
  }

  .message-box {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    .message {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 32px 24px;
      width: 80%;
      max-width: 450px;
      min-height: 200px;
      background-color: white;
      border-radius: 3px;
    }

    .messageDescription {
      color: black;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .continueButton {
      margin-left: auto;
      margin-top: auto;
    }
  }
}

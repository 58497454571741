//////////////////// CORE COLOURS CONTROLLED BY CUSTOMER////////////////
$primary-color: #a42a2e;
$button-color: #a42a2e;

$warning-color: #f46608;
$good-color: #478145;
$bad-color: #963d4b;

$background-color: white; //#f5f2f0;

$gray-base: #231f20 !default;
$gray-darkest: #131313 !default;
$gray-darker: #272727 !default;
$gray-dark: #515151 !default;
$gray: #707070 !default;
$gray-light: #c7c7c7 !default;
$gray-lighter: #e0e0e0 !default;
$gray-lightest: #f4f4f4 !default;
$gray-white: #fafafa !default;

$content-color: $gray;
$gray-header: #303133;

// responsive variables
$cart-width-sm: 250px;

//== Media queries breakpoints

// Extra small screen / phone
$screen-ip5: 320px !default;
$screen-ip5-min: $screen-ip5 !default;

// super small screen (more common than 320)
$screen-xxs: 360px !default;
$screen-xxs-min: $screen-xxs !default;

// Extra small screen / phone
$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
$screen-md: 1024px !default;
$screen-md-min: $screen-md !default;
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

$form-input-height: 34px;

$margin: 16px;
$padding: 20px;
$border-radius: 3px;
.Login {
  .inputContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;

    input {
      width: 50%;
    }

    .inputDomainLabel {
      font-weight: bold;
      color: black;
    }

    &.username {
      input {
        border: none;
      }

      border-bottom: 1px solid #c7c7c7;
    }
  }
}

@use '../variables';
@use '../mixins';

///////////////////////////////////////////////////////////////////
//                  CUSTOM STYLES FOR FORM & ICONS               //
///////////////////////////////////////////////////////////////////

form {
  background: white;

  input,
  select,
  textarea {
    width: 100%;
    height: 50px;
    border: none;
    border-bottom: 1px solid variables.$gray-light;
    font-size: 16px;
    line-height: 24px;
    @include mixins.border-radius(0);
    color: variables.$gray-dark;
    margin: variables.$form-input-height 0 2px 0;
    padding: 15px 8px 10px;
    outline: none !important;
    -webkit-appearance: none;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px white inset;
      box-shadow: 0 0 0px 1000px white inset;
    }

    &.alwaysactive {
      background: white;
    }

    &:focus {
      border: none;
      border-bottom: 1px solid variables.$gray-dark;
      background: white;
      color: variables.$gray-darker;
    }

    &.ng-valid {
      border-bottom: 1px solid variables.$good-color;
      color: variables.$good-color;
      background: white;

      &:-webkit-autofill {
        -webkit-text-fill-color: variables.$good-color !important;
      }
      &:focus {
        color: variables.$gray-dark;
        border-bottom: 1px solid variables.$gray-dark;
      }
    }

    &.ng-invalid.ng-dirty,
    &.ng-pristine.ng-invalid.hascontent {
      border-bottom: 1px solid variables.$bad-color;
      color: variables.$bad-color;
      background: white;
      &:focus {
        color: variables.$gray-dark;
        border-bottom: 1px solid variables.$gray-dark;
      }
      + .input-icon {
        display: none;
        color: variables.$bad-color;
      }
      &:focus + .input-icon {
        color: variables.$gray;
      }
    }

    &.notrequired.ng-pristine {
      + .icon-security:before {
        content: "\e633";
      }
      + .icon-refresh:before {
        content: "\e901";
      }
    }

    &.ng-valid.empty {
      border-bottom: 1px solid variables.$gray-light;
      + .icon-password:before {
        content: "\e633";
      }
      + .icon-house:before {
        content: "\e635";
      }

      &:focus {
        background: white;
      }
    }
  }
}

form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-top: 36px;
  background: white;

  &.not-required {
    &.ng-pristine.ng-valid {
      color: variables.$gray-dark;
      border-bottom: 1px solid variables.$gray-dark;
    }

    &.ng-valid.empty {
      color: variables.$gray;
    }
  }
}

textarea {
  transition: height 0.2s ease-in;

  &:focus,
  &.ng-touched,
  &.ng-dirty {
    height: 100px;
  }
}

//////////////////// FLOAT FORM PATTERN /////////////////////
form {
  .floatinput {
    position: relative;
    max-width: 500px;
    margin: 0 auto;

    input,
    textarea,
    select {
      font-size: 16px;
      line-height: 20px;
    }

    label {
      @include mixins.transition();
      @include mixins.abs-pos(variables.$form-input-height + 18px, auto, auto, 0);
      z-index: 100;
      color: variables.$content-color;
      font-size: 18px;
      display: inline-block;
      padding: 0;
      font-weight: bold;
      pointer-events: none;

      &.active {
        top: variables.$form-input-height - 10px;
        font-size: 1.1em;

        .warning {
          font-size: 1.1em;
        }
      }
    }

    .input-icon,
    .select-icon {
      @include mixins.abs-pos(60px, 10px, auto, auto);
      font-size: 12px;
      width: 20px;
      pointer-events: none;
      color: variables.$gray;
    }

    .errorMessage {
      @include mixins.abs-pos(88px, 0, auto, auto);
      color: variables.$bad-color;
      font-size: 14px;
      line-height: 1.1em;
    }

    &.col {
      label {
        left: 15px;
      }

      .errorMessage {
        right: 15px;
      }
    }
  }
}

// Iphone 5
@media (max-width: variables.$screen-ip5) {
  form {
    input,
    select,
    textarea {
      margin-top: 30px;
    }
  }
}

@use 'variables';
@use 'sass:math';

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: normal;
  color: variables.$gray-darker;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: variables.$margin;
  margin-bottom: math.div(variables.$margin, 2);

  small,
  .small {
    font-size: 65%;
  }
}
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: math.div(variables.$margin, 2);
  margin-bottom: math.div(variables.$margin, 3);

  small,
  .small {
    font-size: 75%;
  }
}

h1,
.h1 {
  font-size: 36px;
  line-height: 1.2em;
}
h2,
.h2 {
  font-size: 28px;
  line-height: 1.2em;
}
h3,
.h3 {
  font-size: 22px;
  line-height: 1.8em;
}
h4,
.h4 {
  font-size: 20px;
  line-height: 1.8em;
}
h5,
.h5 {
  font-size: 18px;
  line-height: 1.8em;
}
h6,
.h6 {
  font-size: 16px;
  line-height: 1.8em;
}

h1.page-title {
  width: 100%;
  text-align: center;
  margin: 1em 0;
  font-size: 6em;
}

// Body text
// -------------------------

p {
  font-size: 1.4em;
  line-height: 1.3em;
}

.title {
  color: variables.$primary-color;
}

.green,
.good {
  color: variables.$good-color;
}

.red,
.bad {
  color: variables.$bad-color;
}

.warning {
  color: variables.$warning-color;
}

.uppercase {
  text-transform: uppercase;
}

///////////////////////////// RESPONSIVE ///////////////////////////////////
@media (max-width: variables.$screen-sm) {
  h1.page-title {
    font-size: 40px;
  }
}

///////////////////////////// RESPONSIVE ///////////////////////////////////
@media (max-width: variables.$screen-xs) {
  h1.page-title {
    font-size: 30px;
  }
}

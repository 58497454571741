@use 'variables';

  // Clear after floats
  @mixin clearfix {
    zoom:1;
    &:before, &:after {
      content: "\0020"; 
      display: block; 
      height: 0; 
      overflow: hidden; 
    }
    &:after {
      clear: both;
    }
  }

  @mixin user-select($select) {
    -webkit-user-select: $select;
        -moz-user-select: $select;
        -ms-user-select: $select; // IE10+
            user-select: $select;
  }
  
  // Apply clearfix to this classes by default
  .clearfix{ 
    @include clearfix;
  }
  
  // Responsive mixins
  @mixin responsive($res)
  {
    @media screen and (max-width: $res)
    {
      @content;
    }
  }
  
  
  @mixin responsiveratio($res, $ratio)
  {
    @media screen and (max-width: $res) and (max-aspect-ratio: $ratio)
    {
      @content;
    }
  }
  
  
  @mixin responsiveMaxMin($resMax, $resMin)
  {
    @media screen and (max-width: $resMax) and (min-width: $resMin)
    {
      @content;
    }
  }
  
  
  @mixin mobilefirst($res)
  {
    @media screen and (min-width: $res)
    {
      @content;
    }
  }
  
  @mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
  }
  
  @mixin abs-pos ($top: auto, $right: auto, $bottom: auto, $left: auto) {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    position: absolute;
  }
  
  @mixin rel-pos ($top: auto, $right: auto, $bottom: auto, $left: auto) {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    position: relative;
  }
  
  @mixin fix-pos ($top: auto, $right: auto, $bottom: auto, $left: auto) {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    position: fixed;
  }
  
  @mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model; // Safari <= 5
        -moz-box-sizing: $box-model; // Firefox <= 19
            box-sizing: $box-model;
  }
  
  @mixin border-radius($radius: $border-radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box;  /* stops bg color from leaking outside the border: */
    overflow:hidden;
  }
  
  @mixin box-shadow($top:3px, $left:3px, $blur:3px, $color:rgba(0,0,0,0.3), $inset: false) {
    @if $inset {
      -webkit-box-shadow:inset $top $left $blur $color;
      -moz-box-shadow:inset $top $left $blur $color;
      box-shadow:inset $top $left $blur $color;
    } @else {
      -webkit-box-shadow: $top $left $blur $color;
      -moz-box-shadow: $top $left $blur $color;
      box-shadow: $top $left $blur $color;
    }
  }
  
  @mixin background-gradient($fromColor, $toColor) { 
    background: $fromColor; /* Old browsers */
    background: -moz-linear-gradient(top,  $fromColor 0%, $toColor 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  $fromColor 0%,$toColor 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  $fromColor 0%,$toColor 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$fromColor, endColorstr=$fromColor,GradientType=0 ); /* IE6-9 */
  }
  
  
  @mixin background-opacity($color, $opacity: 0.3) {
      background: $color; /* The Fallback */
      background: rgba($color, $opacity);
  }
  
  @mixin transition($transition-property:all, $transition-time: 0.3s, $method: ease-out) {
    -webkit-transition: $transition-property $transition-time $method;
    -moz-transition: $transition-property $transition-time $method;
    -ms-transition: $transition-property $transition-time $method;
    -o-transition: $transition-property $transition-time $method;
    transition: $transition-property $transition-time $method;
  }
  
  
  @mixin text-shadow($horizOffset:3px, $vertOffset:3px, $blur:3px, $color:rgba(0,0,0,0.3)) {
      -webkit-text-shadow: $horizOffset $vertOffset $blur $color;
      -moz-text-shadow: $horizOffset $vertOffset $blur $color;
      -ms-text-shadow: $horizOffset $vertOffset $blur $color; /* IE 10+ */
      -o-text-shadow: $horizOffset $vertOffset $blur $color;
      text-shadow: $horizOffset $vertOffset $blur $color;
    }
  
  @mixin overflow-y() {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
  
    &::-webkit-scrollbar {
        width: 0 !important;
    }
  }
  
  
  @mixin overflow-x() {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
  
    &::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
    }
  }
   
  @mixin overflow-x() {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
  
    &::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
    }
  }
  
  @mixin hover-desktop() {
    @media (hover: hover) {
      @content;
    }
  }
  
  @mixin hover-touch() {
    @media (hover: coarse) {
      @content;
    }
  }
